import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`You can install the Ruby SDK using RubyGems or Bundler with the commands listed below:`}</p>
    <h4 {...{
      "id": "using-rubygems"
    }}>{`Using RubyGems`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`gem install paymentsds-mpesa
`}</code></pre>
    <h4 {...{
      "id": "using-bundler"
    }}>{`Using Bundler`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`#Gemfile
gem 'paymentsds-mpesa'
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      